@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700;900&display=swap');

@tailwind base;
@tailwind components;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Noto Sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'Noto Sans', sans-serif;
  @apply text-4xl lg:text-6xl font-extrabold !my-0 !py-0;
}

h2 {
  font-family: 'Noto Sans', sans-serif;
  @apply text-2xl  font-extrabold !my-0 !py-0 uppercase italic;
}

h3 {
  font-family: 'Noto Sans', sans-serif;
  @apply text-black text-lg font-bold uppercase italic;
}

@keyframes marquee-left {
  from {
    background-position: center 0%;
  }
  to {
    background-position: center 500%;
  }
}

@keyframes marquee-right {
  from {
    background-position: center 500%;
  }
  to {
    background-position: center 0%;
  }
}

.marquee-animation-left {
  animation: marquee-left 200s infinite linear alternate;
}

.marquee-animation-right {
  animation: marquee-right 200s infinite linear alternate;
}

@tailwind utilities;